import "./includes/core-landing-pages-vanilla";
import "./includes/activity";
import "./includes/header-desktop";

import React from "react";

import { loadableReady } from "@loadable/component";
import { hydrateRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { ModalManagerLanding } from "@app/components/modals";
import { SearchFormClient } from "@app/components/search-form/search-form-client";
import { HorizonContextProvider } from "@app/context/horizon-context";
import { ReactQueryProvider } from "@app/context/react-query-provider";
import { TRUSTPILOT_WIDGET_CLASS } from "@app/modules/trustpilot/components/trustpilot-constants";
import { LiteServiceProviderClient } from "@app/services/context-providers/lite-service-provider-client-base";
import hotjar from "@app/tracking/hotjar";
import { yieldToMain } from "@app/utils/scheduler-yield";

import { initQuickMenuTracking } from "../components/landing-pages/quick-access-menu/quick-access-menu-client";
import { PriceCalendarBehaviour } from "../modules/price-calendar/price-calendar-behaviour";
import { Reviews } from "../modules/reviews";
import { BB, buildLandingStore } from "./setups/landing";

// Hydration can start before DOMContentLoaded since ATF is already loaded at this point
loadableReady(() => {
  if (BB.whitelabel_shared_info) {
    window.localStorage.setItem(
      `${BB.whitelabel}_shared_info`,
      JSON.stringify(BB.whitelabel_shared_info)
    );
  }

  if (!window.store) {
    window.store = buildLandingStore();
  }

  const root_id = "header-search";
  const container = document.getElementById(root_id) as HTMLElement;
  const has_affiliate_checkbox: boolean =
    !!window.BB.features.LANDING_PAGES_SHOULD_SHOW_AFFILIATE_CHECKBOX;
  const env = typeof window !== "undefined" ? window.BB.config.env : null;

  hydrateRoot(
    container,
    <HorizonContextProvider environment={env}>
      <Provider store={window.store}>
        <LiteServiceProviderClient BB={window.BB} I18N={window.__I18N__}>
          <ReactQueryProvider>
            <SearchFormClient
              with_affiliate_checkbox={has_affiliate_checkbox}
              affiliate_checked
            />
            <ModalManagerLanding />
          </ReactQueryProvider>
        </LiteServiceProviderClient>
      </Provider>
    </HorizonContextProvider>
  );
});

// BTF content must be fully loaded before we initialize the vanilla elements
function initBtf() {
  loadableReady(() => {
    if (!window.store) {
      window.store = buildLandingStore();
    }

    const initLandingPageVanillaElements = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const disable_third_party_scripts = urlParams.get(
        "disable_third_party_scripts"
      );

      Reviews.init(window.tracker);
      new PriceCalendarBehaviour().init(window.tracker, window.document);

      initQuickMenuTracking(window.tracker);

      if (disable_third_party_scripts !== "all") {
        const trustpilot = window.Trustpilot;
        if (trustpilot) {
          const trustboxes = document.querySelectorAll<HTMLElement>(
            `.${TRUSTPILOT_WIDGET_CLASS}`
          );
          trustboxes.forEach(trustbox => {
            trustpilot.loadFromElement(trustbox);
          });
        }

        if (BB.experiments.auto_complete_suggestions === "1") {
          try {
            hotjar.trigger("autocomplete");
          } catch (err) {}
        }

        import(
          /* webpackChunkName: "web-vitals" */ "./includes/web-vitals-reporting"
        ).then(({ WebVitalsReporting }) => {
          new WebVitalsReporting(
            window.BB.inst.request_id,
            window.BB.experiments,
            window.BB.entity,
            window.BB.config.locale,
            window.BB.device
          );
        });
      }
    };

    yieldToMain().then(initLandingPageVanillaElements);
  });
}

if (document.readyState !== "loading") {
  // Document is already loaded and ready
  initBtf();
} else {
  // Wait for the document to be fully loaded
  document.addEventListener("DOMContentLoaded", () => {
    initBtf();
  });
}
